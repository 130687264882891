import { Controller } from '@hotwired/stimulus';
import hotkeys from 'hotkeys-js'

/**
 * Responsible for controlling the record iterator via keyboard events
 */
export default class RecordIteratorController extends Controller {
  static targets = ['nextButton', 'previousButton']
  static values = { scope: String, default: 'all' }

  connect() {
    super.connect()
    if (!this.hasNextButtonTarget) {
      console.error('RecordIteratorController must have a nextButton target')
    }
    if (!this.hasPreviousButtonTarget) {
      console.error('RecordIteratorController must have a previousButton target')
    }

    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    hotkeys('right', { scope: this.scopeValue }, this.next)
    hotkeys('left', { scope: this.scopeValue }, this.previous)

    if (this.hasScopeValue) {
      this._originalScopeValue = hotkeys.getScope();
      hotkeys.setScope(this.scopeValue)
    }
  }

  disconnect() {
    super.disconnect()
    if (this.hasScopeValue) {
      hotkeys.deleteScope(this.scopeValue)
    }

    if (this._originalScopeValue) {
      hotkeys.setScope(this._originalScopeValue)
    }
  }

  next(event) {
    if (event.target instanceof HTMLInputElement || this.nextButtonDisabled()) {
      return
    }
    event.preventDefault();
    this.nextButtonTarget.click();
  }

  previous(event) {
    if (event.target instanceof HTMLInputElement || this.previousButtonDisabled()) {
      return
    }
    event.preventDefault();
    this.previousButtonTarget.click()
  }

  nextButtonDisabled() {
    return window.getComputedStyle(this.nextButtonTarget).pointerEvents === 'none'
  }

  previousButtonDisabled() {
    return window.getComputedStyle(this.previousButtonTarget).pointerEvents === 'none'
  }
}