// Configure Stimulus development experience
import { Application } from "@hotwired/stimulus"
const initialize_stimulus = Application.start()
initialize_stimulus.debug = false
window.Stimulus = initialize_stimulus

// third party controllers
import Dropdown from 'stimulus-dropdown'
window.Stimulus.register("dropdown", Dropdown)

import {
  AutoSubmitFormController,
  DetectDirtyFormController,
  SignalDisableController,
  SignalEnableController,
  SignalInputController,
  SignalVisibilityController,
  PresenceController,
  ScrollToTopController} from 'stimulus-library'
window.Stimulus.register("auto-submit-form", AutoSubmitFormController)
window.Stimulus.register("signal-disable", SignalDisableController)
window.Stimulus.register("signal-enable", SignalEnableController)
window.Stimulus.register("signal-input", SignalInputController)
window.Stimulus.register("signal-visibility", SignalVisibilityController)
window.Stimulus.register("presence", PresenceController)
window.Stimulus.register("scroll-to-top", ScrollToTopController)
window.Stimulus.register('detect-dirty-form', DetectDirtyFormController)

import CheckboxSelectAll from 'stimulus-checkbox-select-all'
window.Stimulus.register('checkbox-select-all', CheckboxSelectAll)


// custom controllers
import TomSelectController from "./controllers/tom_select_controller"
window.Stimulus.register("tom-select", TomSelectController)

import FlatpickrController from "./controllers/flatpickr_controller"
window.Stimulus.register("flatpickr", FlatpickrController)

import InvalidFieldController from "./controllers/invalid_field_controller"
window.Stimulus.register("invalid-field", InvalidFieldController)

import PopulatedFieldsController from "./controllers/populated_fields_controller"
window.Stimulus.register("populated-fields", PopulatedFieldsController)

import PopulatedFieldsetController from "./controllers/populated_fieldset_controller"
window.Stimulus.register("populated-fieldset", PopulatedFieldsetController)

import FormController from "./controllers/form_controller"
window.Stimulus.register("core-form", FormController)

import AutonumericController from './controllers/autonumeric_controller'
window.Stimulus.register('autonumeric', AutonumericController)

import CounterController from './controllers/counter_controller'
window.Stimulus.register('counter', CounterController)

import TreeExpansionController from './controllers/tree_expansion_controller'
window.Stimulus.register('tree-expansion', TreeExpansionController)

// view component controllers
import TurboModalController from '../view_components/turbo_modal/turbo_modal'
window.Stimulus.register('turbo-modal', TurboModalController)

import TurboDrawerController from '../view_components/turbo_drawer/turbo_drawer'
window.Stimulus.register('turbo-drawer', TurboDrawerController)

import TooltipController from '../view_components/tooltip/tooltip'
window.Stimulus.register('tooltip', TooltipController)

import AlertController from '../view_components/./alert_component/alert_component'
window.Stimulus.register('alert', AlertController)

import LiveInputFieldController from '../view_components/live_input_field/live_input_field'
window.Stimulus.register('live-input', LiveInputFieldController)

import LiveStringInputFieldController from '../view_components/live_string_input_field/live_string_input_field'
window.Stimulus.register('live-string-input', LiveStringInputFieldController)

import SidebarController from '../view_components/sidebar/sidebar'
window.Stimulus.register('sidebar', SidebarController)

import DynamicFormSectionController from '../view_components/dynamic_form_section/dynamic_form_section'
window.Stimulus.register('dynamic-section', DynamicFormSectionController)

import BusyFormController from './controllers/busy_form_controller'
window.Stimulus.register('busy-form', BusyFormController)

import PreventEnterController from './controllers/prevent_enter_controller'
window.Stimulus.register('prevent-enter', PreventEnterController)

import DetectTruncationController from "./controllers/detect_truncation_controller";
window.Stimulus.register("detect-truncation", DetectTruncationController)

import RecordIteratorController from "../view_components/record_iterator_control/record_iterator_control"
window.Stimulus.register('record-iterator', RecordIteratorController)

import TurboCheckboxController from './controllers/turbo_checkbox_controller'
window.Stimulus.register('turbo-checkbox', TurboCheckboxController)

import ToggleClassController from './controllers/toggle_class_controller'
window.Stimulus.register('cls-toggle', ToggleClassController)

import InjectFormDataController from './controllers/inject_form_data_controller'
window.Stimulus.register('inject-form-data', InjectFormDataController)

import AnchorController from './controllers/anchor_controller'
window.Stimulus.register('anchor', AnchorController)