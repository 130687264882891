import { Controller } from '@hotwired/stimulus'

/**
 * @description Stimulus controller to update the href of anchor tags with a query parameter
 */
export default class AnchorController extends Controller {
  static targets = ['anchor']

  editQuery(event) {
    let activeTab = event.detail.name;
    let anchorTargets = this.anchorTargets;
    anchorTargets.forEach((anchor) => {
      let url = new URL(anchor.href);
      let searchParams = url.searchParams;
      let searchParamKey = event.params.editQuery;
      searchParams.set(searchParamKey, activeTab)
      anchor.href = url.toString();
    });
  }
}